<template>
  <div class="container">
    <div class="login-box">
      <el-form ref="loginForm" :rules="rules" :model="form" class="login-form">
        <el-form-item label="">
          <div class="title">营销罗盘</div>
        </el-form-item>
        <el-form-item label="" prop="username">
          <el-input
            type="text"
            class="input"
            placeholder="请输入登录ID"
            v-model="form.username"
          >
          <i slot="prefix" class="el-input__icon user_icon"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            type="password"
            class="input"
               placeholder="请输入密码"
            v-model="form.password"
          >
          <i slot="prefix" class="el-input__icon pwd_icon"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="checked" class="remind">记住密码</el-checkbox>
        </el-form-item>
        <el-button class="login-btn" @click="login">登录</el-button>
      </el-form>
    </div>
  </div>
</template>
<script>
import {jump} from "@/utils/index";
import {getCookie} from "@/utils/cookie.js";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules:{
        username:[
          {required:true,message:" "}
        ],
        password:[
          {required:true,message:" "}
        ]
      },
      checked:false
    };
  },
  created(){
    console.log(location);
  },
  methods: {
    login() {
       
       
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('login',this.form).then(res=>{
        
            if(res!==""){
              let query={};
              if(res==='company'){
                query.company_id=getCookie("company_ids")
              }
              jump("/"+res,this,query);

              // this.$router.push({path:"/"+res})
            }
          }).catch(err=>{
                this.$toast(err.message);
          })
        }
      });
    },
  },
  beforeCreate() {
    document.querySelector("html").style.height = "100%";
    document.querySelector("body").style.height = "100%";
    document.querySelector("#app").style.height = "100%";
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: url("../../assets/login_bg.png") no-repeat;
  background-size: 100%;
  height: 100%;
  padding-top: 2.777778rem /* 150/54 */;
  .login-box {
    width: 9.222222rem /* 498/54 */;
    height: 8.351852rem /* 451/54 */;
    background: url("../../assets/login_board.png") no-repeat;
    background-size: contain;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-form {
      height: 5.462963rem /* 295/54 */;
      .title {
        text-align: center;
        color: #fff;
        font-size: 0.555556rem /* 30/54 */;
        margin-bottom: 0px;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0.185185rem; /* 10/54 */
      }
      .input {
        ::v-deep .el-input__inner {
          background: rgba(6, 135, 205, 0.3) !important;
          border: 1px solid #116dc3;
          color: #fff;
          height: 0.740741rem /* 40/54 */;
          line-height: 0.740741rem /* 40/54 */;
         
        }
        
      }
      ::v-deep .is-error .el-input__inner{
        border-color: #F56C6C;
      }
      .remind ::v-deep .el-checkbox__inner {
        background: rgba(6, 135, 205, 0.3) !important;
        border: 1px solid #116dc3;
      }
      .remind {
        color: #fff;
      }
      .login-btn {
        width: 6.740741rem /* 364/54 */;
        height: 0.777778rem /* 42/54 */;
        line-height: 0.777778rem /* 42/54 */;
        padding: 0px;
        background: #4aabfe;
        color: #fff;
        font-size: 0.37037rem /* 20/54 */;
        border: none;
      }
    }
  }
  .user_icon{
    width: .296296rem /* 16/54 */;
    height: .333333rem /* 18/54 */;
    background: url("../../assets/user.png") no-repeat;
    background-size: contain;
    display: inline-block;
     line-height:.333333rem /* 18/54 */;
  
  }
  .pwd_icon{
    width: .296296rem /* 16/54 */;
    height: .333333rem /* 18/54 */;
    background: url("../../assets/pwd.png") no-repeat;
    background-size: contain;
    display: inline-block;
     line-height:.333333rem /* 18/54 */;
  }
}
</style>